<template>
  <card>
    <el-form ref="urlForm" :model="form" :rules="rules">
      <div class="row m-0 mt-4 mb-5">
        <div class="col-md-6">
          <label class="label-text">URL*</label>
          <el-form-item prop="url">
            <el-input placeholder="Address" v-model="form.url"></el-input>
          </el-form-item>
        </div>
        <div class="col-md-6">
          <label class="label-text">Status*</label>
          <el-form-item prop="status">
            <el-select v-model="form.status" class="w-100">
              <el-option value="1" label="Active"></el-option>
              <el-option value="0" label="Inactive"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </card>
</template>

<script>
export default {
  name: "UrlCard",
  props: ['url', 'status', 'urlRequired'],
  data() {
    const validateHttps = (rule, value, callback) => {
      if (!(/^https:\/\/(.*)/.test(value)) && this.rules.url[0].required){
        callback(new Error('Please enter https url'))
      }else {
        callback()
      }
    }
    return {
      form: {
        status: '1',
        url: ''
      },
      rules: {
        url: [
          {required: this.urlRequired ?? false, message: 'Please enter the URL', trigger: 'blur'},
          {type: 'url', message: 'Must be a valid url', trigger: 'blur'},
          {validator: validateHttps, trigger: 'blur'}
        ],
        status: [{
          required: true, message: 'Please select a status', trigger: 'change'
        }]
      }
    }
  },
  methods: {
    formValidate() {
      let validForm = true
      this.$refs['urlForm'].validate((valid) => {
        if (!valid) {
          validForm = false;
          return false;
        }
      })
      return validForm
    },
  },
  watch: {
    url(newValue){
      this.form.url = newValue
    },
    status(newValue){
      this.form.status = String(newValue)
    }
  },
  mounted() {
    if (this.url) this.form.url = this.url
    if (this.status) this.form.status = String(this.status)
  },
}
</script>

<style scoped>
.label-text {
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0;
  color: #172B4D;
}

</style>
